var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "signup justify-center",
      attrs: { fluid: "", "fill-height": "" }
    },
    [
      _c(
        "v-form",
        {
          ref: "signupForm",
          attrs: { "lazy-validation": "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleSignUp()
            }
          },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-container",
            { staticClass: "signup-inner background pa-sm-8 mx-auto" },
            [
              _c(
                "v-row",
                {
                  staticClass: "pa-2",
                  attrs: { align: "center", justify: "center" }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("lottie-player", {
                        style: _vm.$vuetify.breakpoint.smAndDown
                          ? "height: 20vh"
                          : "",
                        attrs: {
                          src:
                            "https://assets7.lottiefiles.com/packages/lf20_gjmecwii.json",
                          background: "transparent",
                          speed: "1",
                          autoplay: "",
                          loop: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "6", md: "6" } },
                    [
                      _c(
                        "h1",
                        {
                          staticClass:
                            "text-h4 justify-center secondary--text mb-4 font-weight-black"
                        },
                        [_vm._v("Create Account")]
                      ),
                      _c("v-text-field", {
                        attrs: {
                          label: "Full Name",
                          rules: _vm.nameRules,
                          filled: "",
                          clearable: "",
                          type: "text",
                          required: ""
                        },
                        model: {
                          value: _vm.name,
                          callback: function($$v) {
                            _vm.name = $$v
                          },
                          expression: "name"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "Email",
                          rules: _vm.emailRules,
                          filled: "",
                          clearable: "",
                          type: "text",
                          required: ""
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "input",
                        attrs: {
                          label: "Password",
                          rules: _vm.passwordRules,
                          type: _vm.passwordFieldType,
                          "append-icon": _vm.passwordIcon,
                          filled: "",
                          clearable: "",
                          required: ""
                        },
                        on: { "click:append": _vm.unmaskPassword },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          color: "green",
                          "on-icon": "$checkboxOn",
                          "off-icon": "$checkboxOff"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "subtitle-2 secondary--text" },
                                  [
                                    _vm._v(" I agree to the "),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "a",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "accent--text",
                                                      attrs: {
                                                        target: "_blank",
                                                        href: "/terms"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v(" Terms of Service ")]
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      },
                                      [_vm._v(" Opens in new window ")]
                                    ),
                                    _vm._v(" of Quizi ")
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.terms,
                          callback: function($$v) {
                            _vm.terms = $$v
                          },
                          expression: "terms"
                        }
                      }),
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-large": "",
                                    block: "",
                                    color: "secondary",
                                    type: "submit",
                                    disabled: !_vm.valid || !_vm.terms
                                  }
                                },
                                [_vm._v(" Sign Up ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-large": "",
                                    block: "",
                                    color: "secondary",
                                    outlined: "",
                                    to: "/login"
                                  }
                                },
                                [_vm._v(" Back ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("Loading", {
                attrs: { loading: _vm.loading, text: _vm.signupLoadingText }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }