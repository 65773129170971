<template>
  <v-container class="signup justify-center" fluid fill-height>
    <v-form ref="signupForm" v-model="valid" lazy-validation @submit.prevent="handleSignUp()">
      <v-container class="signup-inner background pa-sm-8 mx-auto">
        <v-row align="center" justify="center" class="pa-2">
          <v-col cols="12" md="6">
            <lottie-player
              src="https://assets7.lottiefiles.com/packages/lf20_gjmecwii.json"
              background="transparent"
              speed="1"
              autoplay
              :style="$vuetify.breakpoint.smAndDown ? 'height: 20vh' : ''"
              loop
            ></lottie-player>
          </v-col>
          <v-col cols="12" lg="6" md="6">
            <h1 class="text-h4 justify-center secondary--text mb-4 font-weight-black">Create Account</h1>

            <v-text-field
              v-model="name"
              label="Full Name"
              :rules="nameRules"
              filled
              clearable
              type="text"
              required
            ></v-text-field>
            <v-text-field
              v-model="email"
              label="Email"
              :rules="emailRules"
              filled
              clearable
              type="text"
              required
            ></v-text-field>
            <v-text-field
              v-model="password"
              label="Password"
              :rules="passwordRules"
              :type="passwordFieldType"
              :append-icon="passwordIcon"
              filled
              clearable
              class="input"
              required
              @click:append="unmaskPassword"
            ></v-text-field>
            <v-checkbox color="green" v-model="terms" on-icon="$checkboxOn" off-icon="$checkboxOff">
              <template v-slot:label>
                <div class="subtitle-2 secondary--text">
                  I agree to the
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a target="_blank" class="accent--text" href="/terms" @click.stop v-on="on"> Terms of Service </a>
                    </template>
                    Opens in new window
                  </v-tooltip>
                  of Quizi
                </div>
              </template>
            </v-checkbox>

            <v-row align="center">
              <v-col cols="12" md="6">
                <v-btn x-large block color="secondary" type="submit" :disabled="!valid || !terms"> Sign Up </v-btn>
              </v-col>

              <v-col cols="12" md="6">
                <v-btn x-large block color="secondary" outlined to="/login"> Back </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <Loading :loading="loading" :text="signupLoadingText" />
      </v-container>
    </v-form>
  </v-container>
</template>
<script>
import { loginSignupMixin } from "@/mixins/loginSignupMixin.js"
import { signup } from "@/api/AuthApi"

export default {
  name: "Signup",
  metaInfo: {
    title: "Create a Qizto Account in a Jiffy and engage your audience",
    titleTemplate: "Qizto | %s ",
    meta: [
      {
        name: "description",
        content:
          "Build Beautiful Customizable Quizzes Easily. No Coding Needed. Put two or more players up against each other. Time to put your audience's knowledge to the test",
      },
    ],
  },
  mixins: [loginSignupMixin],
  data: () => ({
    signupSuccessMessage: "Welcome to Qizto, You've Signed Up",
    terms: false,
    link: { title: "Terms of Service", route: "/terms", target: "_blank" },
    name: "",
    signupLoadingText: "Creating your profile...",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v?.length >= 4 || "Name should be atleast 4 characters",
      (v) => /^[A-Za-z\s]+$/.test(v) || "Only Alphabets and Spaces allowed",
    ],
  }),
  methods: {
    async handleSignUp() {
      if (this.$refs.signupForm.validate()) {
        this.loading = !this.loading
        const email = this.email
        const password = this.password
        const name = this.name
        try {
          await signup(email, password, name)
          this.showAlert(this.signupSuccessMessage)
          this.$router.push("/dashboard")
        } catch (err) {
          this.showAlert(err.message)
        } finally {
          this.loading = !this.loading
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.signup {
  min-height: 90vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-image: url("~@/assets/background-bottom.svg"),
    linear-gradient(174deg, rgba(255, 243, 224, 1) 20%, rgba(255, 223, 173, 1) 78%);
  background-blend-mode: overlay;
  .signup-inner {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }
}
</style>
